import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import State from '../components/State';
import Overlay from '../components/Overlay';
import Reloj from '../components/Reloj';
import useAudio from '../hooks/useAudio';

import logoAllegra from '../assets/images/common/logo-allegra.png';
import jugar from '../assets/images/home/boton-jugar.png';
import nivel from '../assets/images/level-1/nivel1.png';
import mujerFeliz from '../assets/images/level-1/mujer-feliz.png';
import titulo from '../assets/images/level-1/cuadro-nivel1.png';
import producto from '../assets/images/level-1/caja-allegra-120.png';

import mujerBrava from '../assets/images/level-1/mujer-brava.png';
import mensaje from '../assets/images/level-1/cuadro-organicemos.png';

import polvoPregunta from '../assets/images/level-1/texto-reto1-pregunta.png';
import polvoRespuesta1 from '../assets/images/level-1/reto1-boton-A.png';
import polvoRespuesta2 from '../assets/images/level-1/reto1-boton-B.png';
import polvoRespuesta3 from '../assets/images/level-1/reto1-boton-C.png';
import polvoMujerCuadro from '../assets/images/level-1/mujer-cuadro.png';
import polvoBien from '../assets/images/level-1/cuadro-muy-bien.png';
import polvoIncorrecto from '../assets/images/level-1/texto-resp-incorrecta.png';
import polvoUps from '../assets/images/level-1/texto-ups.png';
import polvoSegundos from '../assets/images/level-1/texto-reto1-segundos.png';

import crucigramaPregunta from '../assets/images/level-1/texto-reto2-pregunta.png';
import crucigramaTablero from '../assets/images/level-1/reto2-sopa-letras.png';
import crucigramaPalabras from '../assets/images/level-1/reto2-palabras.png';
import crucigramaGenial from '../assets/images/level-1/cuadro-genial.png';
import crucigramaSegundos from '../assets/images/level-1/texto-reto2-segundos.png';

import escondidasPregunta from '../assets/images/level-1/texto-reto3-pregunta.png';
import escondidasHabitacion from '../assets/images/level-1/reto3-foto.png';
import escondidasMas from '../assets/images/level-1/reto3-mas.png';
import escondidasProducto from '../assets/images/level-1/reto3-caja.png';
import escondidasLograste from '../assets/images/level-1/cuadro-lograste.png';
import escondidasSegundos from '../assets/images/level-1/texto-reto3-segundos.png';

import referencias from '../assets/images/level-1/texto-ref.png';
import cerrar from '../assets/images/common/boton-cerrar.png';

import iconoMusicaOn from '../assets/images/common/icono-musica-on.png';
import iconoMusicaOff from '../assets/images/common/icono-musica-off.png';

const Level1 = () => {
  const { state, setState } = React.useContext(AppContext);
  const navigate = useNavigate();
  const audio = useAudio();

  console.log('level1');

  /**
   * HANDLE STATE
   * 
   * @param {string} screen   Guarda la pantalla actual
   * @param {string} clock    Dice si el reloj esta o no activo
   */

  const handleState = (screen) => {
    setState({
      ...state,
      screen: screen,
      // clock: clock,
    })
  }

  /**
   * HANDLE NAVIGATE
   * Cierra el modal
   * Abre la ruta solicitada
   * @param {string} url    Ruta a la cual se dirige
   */

   const handleNavigate = (url) => {
    setState({
      ...state,
      screen: false,
    })
    navigate('/'+url, { replace: true });
  }

  /**
   * HANDLE LETTER
   * Captura a que cuadro se le hizo click
   * Pinta de color el cuadro seleccionado y lo deshabilita
   * Gana si todos los cuadros han sido seleccionados
   * @param {event} e Evento del click
   */

   const handleLetter = (e) => {
    e.target.classList.add('active');

    const letters = document.querySelectorAll('.Level1-crucigrama-grid .Level1-crucigrama-letter');
    const lettersActived = document.querySelectorAll('.Level1-crucigrama-grid .Level1-crucigrama-letter.active');
    
    if (letters.length === lettersActived.length) {
      console.log('ganaste');

      const tableroWrapper = document.querySelector('.Level1-crucigrama-tablero-wrapper');
      tableroWrapper.classList.add('bounceIn');
      
      setTimeout(() => {
        handleState('crucigrama-ok');
      }, 1500);
    }
  }
  
  /**
   * HANDLE BOX
   * Pinta la caja encontrada
   * Gana si encuentra todas las cajas
   * @param {event} e Evento del click
   */
  
  const handleBox = (e) => {
    e.target.classList.add('active');
    e.target.classList.add('zoomIn');
    
    const boxes = document.querySelectorAll('.Level1-escondidas-caja');
    const boxesActived = document.querySelectorAll('.Level1-escondidas-caja.active');

    const scoreNumber = document.querySelector('.Level1-escondidas-score-number');
    scoreNumber.innerHTML = boxesActived.length;
    
    if (boxes.length === boxesActived.length) {
      console.log('ganaste');

      setTimeout(() => {
        handleState('escondidas-ok');
      }, 1000);
    }
  }

  /**
   * HANDLE MUSIC
   * Inicia la musica si no esta sonando
   */

   const handleMusic = () => {
    console.log('evaluando musica');
    const music = document.querySelector('.audio-music')

    if (music.paused) {
      console.log('musica esta quieta');
      audio.restart('music');
    } else {
      console.log('musica esta sonando');
    }
  }

  /**
   * SWITCH MUSIC
   * Enciende o apaga la musica
   */

  const switchMusic = (state) => {
    const iconoMusica = document.querySelector('.Home-icono-musica');

    if (iconoMusica) {
      if (state === 'on') {
        audio.volumeOn('music');
        iconoMusica.src = iconoMusicaOn;
        localStorage.setItem('musicAlegra', 'on');
      } else if (state === 'off') {
        audio.volumeOff('music');
        iconoMusica.src = iconoMusicaOff;
        localStorage.setItem('musicAlegra', 'off');
      } else if (state === 'toggle') {
        if (audio.isPlay('music')) {
          if (localStorage.getItem('musicAlegra') === 'on') {
            switchMusic('off');
          } else if (localStorage.getItem('musicAlegra') === 'off') {
            switchMusic('on');
          }
        } else {
          audio.restart('music');
          switchMusic('on');
        }
      }
    }
  }

  setTimeout(() => {
    if (audio.isPlay('music')) {
      if (localStorage.getItem('musicAlegra') === 'on') {
        switchMusic('on');
      } else if (localStorage.getItem('musicAlegra') === 'off') {
        switchMusic('off');
      }
    } else {
      switchMusic('off');
    }    
  }, 500);

  /**
   * SAVE SCORE
   * Guarda el puntaje en el servidor
   */

  const saveScore = () => {
    const score = {
      name: localStorage.getItem('fullname'),
      pharmacy: localStorage.getItem('pharmacy'),
      score: Number(localStorage.getItem('gamePolvoScore')) + Number(localStorage.getItem('gameCrucigramaScore')) + Number(localStorage.getItem('gameEscondidasScore')),
      time: Number(localStorage.getItem('gamePolvoTime')) + Number(localStorage.getItem('gameCrucigramaTime')) + Number(localStorage.getItem('gameEscondidasTime')),
    }
    
    if (localStorage.getItem('gamePolvo') === 'finish' && localStorage.getItem('gameCrucigrama') === 'finish' && localStorage.getItem('gameEscondidas') === 'finish') {
      console.log('Save score!');

      const rankingAllegraLevel1 = localStorage.getItem('rankingAllegraLevel1');
  
      if (!rankingAllegraLevel1) {
        console.log('no existe ranking');
        localStorage.setItem('rankingAllegraLevel1', JSON.stringify([score]));
      } else {
        console.log('ya existe ranking');
        const ranking = JSON.parse(localStorage.getItem('rankingAllegraLevel1'));
        ranking.push(score);
  
        localStorage.setItem('rankingAllegraLevel1', JSON.stringify(ranking));
      }
      
      localStorage.setItem('gamePolvo', 'saved');
      localStorage.setItem('gameCrucigrama', 'saved');
      localStorage.setItem('gameEscondidas', 'saved');
    }
  }

  /**
   * USE EFFECT
   * Acciones iniciales
   */

  // React.useEffect(() => {
  //   handleState('seccion4-ok');
  // }, []);

  return (
    <div className='Level1'>
      <State />
      <div className='Level1-background fadeIn' />
      <img className='Level1-logo-allegra' src={logoAllegra} alt="Logo Alegra" />
      <img className='Level1-nivel' src={nivel} alt="Nivel" />
      <button className='button right bottom' onClick={() => { 
        handleNavigate('welcome'); 
        audio.play('modalOut') 
      }} >MENU</button>
      <img className='Home-icono-musica absolute left bottom' src={iconoMusicaOn} alt="Musica" onClick={() => switchMusic('toggle')} />

      {/* Presentacion */}

      {!state.screen &&
        <React.Fragment>
          { localStorage.setItem('gamePolvo', 'ready') }
          { localStorage.setItem('gamePolvoScore', 0) }
          { localStorage.setItem('gamePolvoTime', 0) }
          { localStorage.setItem('gameCrucigrama', 'ready') }
          { localStorage.setItem('gameCrucigramaScore', 0) }
          { localStorage.setItem('gameCrucigramaTime', 0) }
          { localStorage.setItem('gameEscondidas', 'ready') }
          { localStorage.setItem('gameEscondidasScore', 0) }
          { localStorage.setItem('gameEscondidasTime', 0) }

          <img className='Level1-mujer-feliz slideUp' src={mujerFeliz} alt="Mujer" />
          <img className='Level1-producto zoomIn' src={producto} alt="Producto" />
          <img className='Level1-titulo' src={titulo} alt="Titulo" />
          <img className='Home-jugar-btn' src={jugar} alt="Jugar" onClick={() => { 
            handleState('instrucciones');
            handleMusic();
            audio.play('button');
          }} />
        </React.Fragment>
      }

      {/* Instrucciones */}

      {state.screen === 'instrucciones' &&
        <React.Fragment>
          <img className='Level1-mujer-brava' src={mujerBrava} alt="Mujer" />
          <img className='Level1-mensaje zoomIn' src={mensaje} alt="Titulo" onClick={() => { 
            handleState('polvo');
            audio.play('button');
          }} />
        </React.Fragment>
      }

      {/* Polvo */}

      {state.screen === 'polvo' &&
        <Overlay>
          <div className="Level1-polvo-wrapper">
            <img className='Level1-polvo-pregunta' src={polvoPregunta} alt="Pregunta" />
            <div className="Level1-polvo-respuestas">
              <img className='Level1-polvo-respuesta1' src={polvoRespuesta1} alt="Polvo respuesta" onClick={() => { 
                handleState('polvo-error');
                audio.play('button');
              }} />
              <img className='Level1-polvo-respuesta2' src={polvoRespuesta2} alt="Polvo respuesta" onClick={() => { 
                handleState('polvo-error');
                audio.play('button');
              }} />
              <img className='Level1-polvo-respuesta3' src={polvoRespuesta3} alt="Polvo respuesta" onClick={() => { 
                handleState('polvo-ok');
                audio.play('button');
              }} />
            </div>
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut') 
          }} >SALIR</button>
          
          <img className='Level1-polvo-segundos' src={polvoSegundos} alt="Segundos Reto" />
          <Reloj timeOut="24" timeOutScreen={'polvo-timeout'} game="Polvo" />
        </Overlay>
      }

      {/* Polvo - Callbacks */}

      {state.screen === 'polvo-timeout' &&
        <Overlay>
          <img className='Level1-polvo-mujer-brava slideRight' src={mujerBrava} alt="Mujer" />
          <img className='Level1-polvo-ups' src={polvoUps} alt="Timeout" onClick={() => {
            handleState('polvo');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'polvo-error' &&
        <Overlay>
          { localStorage.getItem('gamePolvo') === 'ready' && localStorage.setItem('gamePolvo', 'finish') }
          { localStorage.setItem('gamePolvoScore', 0) }

          <img className='Level1-polvo-mujer-brava slideRight' src={mujerBrava} alt="Mujer" />
          <img className='Level1-polvo-incorrecto' src={polvoIncorrecto} alt="Incorrecto" onClick={() => {
            handleState('crucigrama');
            audio.play('button');
          }} />
        </Overlay>
      }

      {state.screen === 'polvo-ok' &&
        <Overlay>
          { localStorage.getItem('gamePolvo') === 'ready' && localStorage.setItem('gamePolvo', 'finish') }
          { localStorage.setItem('gamePolvoScore', 1) }

          <img className='Level1-polvo-mujer-cuadro slideUp' src={polvoMujerCuadro} alt="Mujer" />
          <img className='Level1-polvo-bien zoomIn' src={polvoBien} alt="Bien" onClick={() => {
            handleState('crucigrama');
            audio.play('button');
          }} />
        </Overlay>
      }

      {/* Crucigrama */}

      {state.screen === 'crucigrama' &&
        <Overlay>
          <div className="Level1-crucigrama-wrapper">
            <img className='Level1-crucigrama-pregunta' src={crucigramaPregunta} alt="Pregunta" />
            <div className="Level1-crucigrama-juego">
              <div className="Level1-crucigrama-tablero-wrapper">
                <img className='Level1-crucigrama-tablero' src={crucigramaTablero} alt="Tablero" />
                <div className="Level1-crucigrama-grid">
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "1" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "3" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "4" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "5" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "6" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "7" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "8" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "9" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "10" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "11" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "1", gridColumn: "12" }} onMouseDown={(e) => handleLetter(e)} />
                  
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "2" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "3" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "4" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "5" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "6" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "7" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "8" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "9" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "10" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "11" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "2", gridColumn: "12" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter' style={{ gridRow: "3", gridColumn: "3" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "3", gridColumn: "10" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter' style={{ gridRow: "4", gridColumn: "4" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "4", gridColumn: "9" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter' style={{ gridRow: "5", gridColumn: "5" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "5", gridColumn: "8" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter' style={{ gridRow: "6", gridColumn: "6" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "6", gridColumn: "7" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "6", gridColumn: "9" }} onMouseDown={(e) => handleLetter(e)} />
                  
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "7", gridColumn: "6" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "7", gridColumn: "7" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "7", gridColumn: "8" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter' style={{ gridRow: "8", gridColumn: "5" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "8", gridColumn: "7" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "8", gridColumn: "8" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter' style={{ gridRow: "9", gridColumn: "4" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "9", gridColumn: "6" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "9", gridColumn: "9" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter' style={{ gridRow: "10", gridColumn: "3" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "10", gridColumn: "5" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "10", gridColumn: "10" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter' style={{ gridRow: "11", gridColumn: "2" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "11", gridColumn: "4" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "11", gridColumn: "11" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter' style={{ gridRow: "12", gridColumn: "1" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "12", gridColumn: "3" }} onMouseDown={(e) => handleLetter(e)} />
                  <div className='Level1-crucigrama-letter' style={{ gridRow: "12", gridColumn: "12" }} onMouseDown={(e) => handleLetter(e)} />

                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                  <div className='Level1-crucigrama-letter-empty' />
                </div>
              </div>
              <div className="Level1-crucigrama-palabras-wrapper">
                <p className='Level1-crucigrama-instruccion'>¡Haz click en cada letra!</p>
                <img className='Level1-crucigrama-palabras' src={crucigramaPalabras} alt="Palabra" />
              </div>
            </div>
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut') 
          }} >SALIR</button>
          
          <img className='Level1-crucigrama-segundos' src={crucigramaSegundos} alt="Segundos Reto" />
          <Reloj timeOut="120" timeOutScreen={'crucigrama-timeout'} game="Crucigrama" />
        </Overlay>
      }

      {/* Crucigrama - Callbacks */}

      {state.screen === 'crucigrama-timeout' &&
        <Overlay>
          <img className='Level1-polvo-mujer-brava slideRight' src={mujerBrava} alt="Mujer" />
          <img className='Level1-polvo-ups' src={polvoUps} alt="Timeout" onClick={() => {
            handleState('crucigrama');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'crucigrama-ok' &&
        <Overlay>
          { localStorage.getItem('gameCrucigrama') === 'ready' && localStorage.setItem('gameCrucigrama', 'finish') }
          { localStorage.setItem('gameCrucigramaScore', 5) }

          <img className='Level1-polvo-mujer-cuadro slideUp' src={polvoMujerCuadro} alt="Mujer" />
          <img className='Level1-crucigrama-genial zoomIn' src={crucigramaGenial} alt="Genial" onClick={() => {
            handleState('escondidas');
            audio.play('button');
          }} />
        </Overlay>
      }

      {/* Escondidas */}

      {state.screen === 'escondidas' &&
        <Overlay>
          <div className="Level1-escondidas-wrapper">
            <img className='Level1-escondidas-pregunta' src={escondidasPregunta} alt="Pregunta" />
            <div className="Level1-escondidas-juego">
              <div className="Level1-escondidas-habitacion-wrapper">
                <img className='Level1-escondidas-habitacion' src={escondidasHabitacion} alt="Habitacion" />
                <div className='Level1-escondidas-caja Level1-escondidas-caja-1' onClick={(e) => handleBox(e)} />
                <div className='Level1-escondidas-caja Level1-escondidas-caja-2' onClick={(e) => handleBox(e)} />
                <div className='Level1-escondidas-caja Level1-escondidas-caja-3' onClick={(e) => handleBox(e)} />
                <div className='Level1-escondidas-caja Level1-escondidas-caja-4' onClick={(e) => handleBox(e)} />
                <div className='Level1-escondidas-caja Level1-escondidas-caja-5' onClick={(e) => handleBox(e)} />
              </div>
              <div className="Level1-escondidas-score-wrapper">
                <img className='Level1-escondidas-mas' src={escondidasMas} alt="Mas" />
                <img className='Level1-escondidas-producto' src={escondidasProducto} alt="Producto" />
                <p className='Level1-escondidas-score'><span className='Level1-escondidas-score-number'>0</span>/5</p>
              </div>
            </div>
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleState(''); 
            audio.play('modalOut') 
          }} >SALIR</button>
          
          <img className='Level1-escondidas-segundos' src={escondidasSegundos} alt="Segundos Reto" />
          <Reloj timeOut="60" timeOutScreen={'escondidas-timeout'} game="Escondidas" />
        </Overlay>
      }

      {/* Escondidas - Callbacks */}

      {state.screen === 'escondidas-timeout' &&
        <Overlay>
          <img className='Level1-polvo-mujer-brava slideRight' src={mujerBrava} alt="Mujer" />
          <img className='Level1-polvo-ups' src={polvoUps} alt="Timeout" onClick={() => {
            handleState('escondidas');
            audio.play('modalOut');
          }} />
        </Overlay>
      }

      {state.screen === 'escondidas-ok' &&
        <Overlay>
          { localStorage.getItem('gameEscondidas') === 'ready' && localStorage.setItem('gameEscondidas', 'finish') }
          { localStorage.setItem('gameEscondidasScore', 5) }

          { saveScore() }

          <img className='Level1-polvo-mujer-cuadro slideUp' src={polvoMujerCuadro} alt="Mujer" />
          <div className="Level1-escondidas-lograste-wrapper zoomIn">
            <img className='Level1-escondidas-lograste' src={escondidasLograste} alt="Lograste" />
            <div className='Level1-escondidas-continuar' onClick={() => {
              handleNavigate('level4');
              audio.play('button');
            }} />
            <div className='Level1-escondidas-menu' onClick={() => {
              handleNavigate('welcome');
              audio.play('button');
            }} />
            <div className='Level1-escondidas-referencias' onClick={() => {
              handleState('referencias');
              audio.play('button');
            }} />
          </div>
          <button className='button right bottom z-200' onClick={() => { 
            handleNavigate('finish');
            audio.play('modalOut');
          }} >CALIFÍCANOS</button>
        </Overlay>
      }

      {/* Referencias */}

      {state.screen === 'referencias' &&
        <Overlay>
          <div>
            <img className='Level1-referencias-close' src={cerrar} alt="Cerrar" onClick={() => {
              handleState('escondidas-ok');
              audio.play('modalOut');
            }} />
            <img className='Level1-referencias' src={referencias} alt='Referencias' />
          </div>
        </Overlay>
      }
    </div>
  );
}

export default Level1;